<template>
  <v-container fluid>
    <state-indicate ref="indicate" v-slot:default="{isLoading}">
      <v-data-table
        :headers="headers"
        :items="orders"
        :page.sync="page"
        :server-items-length="meta.total"
        :items-per-page="meta.per_page"
        :loading="isLoading"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-dialog
              v-model="modal"
              max-width="500px"
            >
              <!--                <template v-slot:activator="{ on, attrs }">-->
              <!--                  <v-btn-->
              <!--                    color="primary"-->
              <!--                    dark-->
              <!--                    class="mb-2"-->
              <!--                    v-bind="attrs"-->
              <!--                    v-on="on"-->
              <!--                  >-->
              <!--                    New Item-->
              <!--                  </v-btn>-->
              <!--                </template>-->
                <v-card>
                  <v-card-title>
                    <span class="headline">Products</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-list-item class="cart-menu_item" v-for="(product, key) in selectedOrder.products" :key="key">
                            <v-list-item-avatar>
                              <img :src="product.image_url"/>
                            </v-list-item-avatar>

                            <v-row no-gutters>
                              <v-col class="pb-0 pt-0" cols="10">
                                <v-list-item-title>{{product.name}}</v-list-item-title>
                              </v-col>
                              <v-col class="pb-0 pt-0" cols="10">
                                <v-list-item-subtitle>Price: R {{product.price}}</v-list-item-subtitle>
                              </v-col>
                              <a :href="product.pivot.download_link" download>
                                Download
                              </a>
                            </v-row>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeMoreInfoModal"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
            </v-dialog>
            <v-toolbar-title>My orders history</v-toolbar-title>
          </v-toolbar>
        </template>
<!--        <template v-slot:item.actions="{ item }">-->
<!--          <v-icon-->
<!--            small-->
<!--            @click="deleteItem(item)"-->
<!--          >-->
<!--            mdi-delete-->
<!--          </v-icon>-->
<!--        </template>-->
        <template v-slot:item.actions="{ item }">
          <v-btn
            :disabled="item.status !== 2"
            @click="moreInfoModal(item)"
            small
          >View Product</v-btn>

        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="meta.last_page"
        ></v-pagination>
      </div>
    </state-indicate>
  </v-container>
</template>

<script>
    import StateIndicate from "../../components/utils/StateIndicate";
    import OrdersApi from "../../api/orders";

    export default {
        name: "MyOrdersHistory",
        data() {
            return {
                modal: false,
                orders: [],
                selectedOrder: {},
                headers: [
                    {text: 'ID', value: 'id'},
                    {text: 'Total amount', value: 'total_amount'},
                    {text: 'Status', value: 'status_name'},
                    {text: 'Created at', value: 'created_at'},
                    {text: 'Actions', value: 'actions'},
                ],
                meta: {},
                page: 1
            }
        },
        watch: {
            async page() {
                this.$refs.indicate.usePromise(this.fetchOrders())
            },
        },
        async mounted() {
            this.$refs.indicate.usePromise(this.fetchOrders())
        },

        methods: {
            moreInfoModal(order) {
                this.modal = true
                this.selectedOrder = order
            },
            closeMoreInfoModal() {
                this.modal = false
            },
            fetchOrders() {
                return OrdersApi.authUserOrders(this.page).then(ApiResponse => {
                    this.orders = ApiResponse.getData();
                    this.meta = ApiResponse.getMeta();
                });
            },
        },
        components: {
            StateIndicate
        }
    }
</script>

<style scoped>

</style>
